import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  message,
  notification,
  Form,
  Row,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import {
  FileDoneOutlined,
  ArrowLeftOutlined,
  CheckCircleOutlined,
  SendOutlined,
} from '@ant-design/icons';
import logo from '../../Assets/img/logo.jpg';
import IMG1 from '../../Assets/img/IMG1.jpg';
import IMG2 from '../../Assets/img/IMG2.jpg';
import IMG3 from '../../Assets/img/IMG3.jpg';
import IMG4 from '../../Assets/img/IMG4.jpg';
import { SearchBar } from '../Atoms/SearchBar';
import { aSearchElements, oInitState, requiredFields } from './TicketConstants';
import { generateQueries } from '../../Utils/query';
import { useFetchOrdersWithApiKey } from '../../Hooks/Order.hook';
import { TicketActions } from './TicketActions';
import {
  useSaveInvoicesWithApiKey,
  useFetchOneBranchWithApiKey,
  useFetchClientsWithApiKey,
  usePatchOrdersWithApiKey,
  useSendInvoicesWithApiKey,
  useStampInvoicesWithApiKey,
} from '../../Hooks';
import { process, FIND_KEY } from '../../Service/Api';
import moment from 'moment';
import { useNavigation } from '../../Hooks';

export const TicketTable = () => {
  let res;
  let resBranch;
  let cadena = window.location.pathname.replace('/tickets/', '').split('&');
  const rfc = cadena[0];
  const branch_id = cadena[1];
  const [branch, setBranch] = useState();
  const [client, setClient] = useState();
  const [search, setSearch] = useState(oInitState);
  const [orders, loading, change, updater] = useFetchOrdersWithApiKey();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalLoading2, setModalLoading2] = useState(false);
  const [selected, setSelected] = useState({});
  const [formRef] = Form.useForm();
  const [searchFlag, setSearchFlag] = useState(false);
  const [, nav] = useNavigation();

  const aColumns = [
    {
      dataIndex: 'folio',
      title: 'Folio',
    },
    {
      dataIndex: 'serie',
      title: 'Serie',
    },
    {
      dataIndex: 'fecha',
      title: 'Fecha Orden',
      render: fecha => <>{moment(fecha, 'YYYYMMDD').format('L')}</>,
    },
    {
      dataIndex: 'total_bruto',
      title: 'Subtotal',
      render: total_bruto => <>$ {total_bruto.toFixed(2)}</>,
    },
    {
      dataIndex: 'total_neto',
      title: 'Total Neto',
      render: total_neto => <>$ {total_neto.toFixed(2)}</>,
    },
    {
      dataIndex: 'id',
      title: 'Acciones',
      render: (_, row) =>
        row.status_invoice === 0 ? (
          <Row>
            {row.status === 1 ? (
              <Button
                onClick={() => {
                  setSelected(row);
                  setModal(true);
                }}
              >
                <FileDoneOutlined />
                Facturar
              </Button>
            ) : (
              <>Cancelado</>
            )}
          </Row>
        ) : (
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Tooltip title="Orden Facturada">
                <Tag color="#7444C8">
                  <CheckCircleOutlined />
                </Tag>
              </Tooltip>
            </Col>
            <Col span={12}>
              <Tooltip title="Orden Facturada">
                <Button
                  danger
                  size="small"
                  onClick={() => {
                    setSelected(row);
                    setModal2(true);
                  }}
                >
                  <SendOutlined />
                </Button>
              </Tooltip>
            </Col>
          </Row>
        ),
    },
  ];

  useEffect(async () => {
    resBranch = await useFetchOneBranchWithApiKey(branch_id);
    setBranch(resBranch);
    res = await useFetchClientsWithApiKey(`rfc=${rfc}`);
    setClient(res.data.data[0]);
  }, []);

  const handleReset = () => {
    setSearchFlag(false);
    setSearch(oInitState);
    change();
  };

  const handleSubmit = async values => {
    setModalLoading(true);

    const stringMethod = values.payment_method.split('&');
    const oPaymentMethodCode = stringMethod[0];
    const oPaymentMethodId = stringMethod[1];

    const stringForm = values.payment_form.split('&');
    const oPaymentFormCode = stringForm[0];
    const oPaymentFormId = stringForm[1];

    console.log('HEREEEE');
    console.log(values);
    console.log(selected);

    let oClienteRegimen = await process(
      FIND_KEY,
      'regimes',
      {},
      { queries: `id=${client?.regime_id}`, skip: 0 }
    );
    let oInvoiceUse = await process(
      FIND_KEY,
      'invoice-uses',
      {},
      { queries: `id=${client?.use_id}`, skip: 0 }
    );
    let oBranchRegimen = await process(
      FIND_KEY,
      'regimes',
      {},
      { queries: `id=${branch?.regime_id}`, skip: 0 }
    );

    console.log(oClienteRegimen);
    console.log(oInvoiceUse);
    console.log(oBranchRegimen);

    let oXMLInvoice = {
      order: selected,
      client: client,
      branch: branch,
      email: values.email,
      subtotal: selected.total_bruto,
      metodoPago: oPaymentMethodCode,
      formaPago: oPaymentFormCode,
      clientRegime: oClienteRegimen?.data?.data[0].code,
      invoiceUse: oInvoiceUse?.data?.data[0].code,
      branchRegime: oBranchRegimen?.data?.data[0].code,
    };

    console.log(oXMLInvoice);
    let responseInvoice = await useStampInvoicesWithApiKey(oXMLInvoice);
    console.log(responseInvoice);
    if (responseInvoice.status === 200) {
      if (!responseInvoice?.data?.status) {
        message.error(`Error en timbrado ${responseInvoice?.data?.error}`);
        setModalLoading(false);
        return;
      } else {
        const incidencias =
          responseInvoice?.data?.stamp?.Incidencias?.Incidencia;

        if (Array.isArray(incidencias) && incidencias.length > 0) {
          message.error(`Incidencia: ${incidencias[0].MensajeIncidencia}`);
          setModalLoading(false);
          return;
        }
        let oInvoice = {
          order_id: selected.id,
          branch_id: selected.branch_id,
          client_id: client.id,
          use_id: client.use_id,
          form_id: oPaymentFormId,
          method_id: oPaymentMethodId,
          invoicing_date: moment().format(),
          stamping_date: moment().format(),
          subtotal: selected.total_bruto,
          cfdi: responseInvoice?.data?.stamp?.xml,
          serie: selected.serie,
          folio: `${selected.folio}`,
          stamping_state: 1,
          status: 1,
          sat_uuid: responseInvoice?.data?.stamp?.UUID,
          complements: `${responseInvoice?.data?.stamp?.UUID}`,
          qr: `${responseInvoice?.data?.stamp?.UUID}`,
          errors: `${responseInvoice?.data?.stamp?.UUID}`,
        };

        let response = await useSaveInvoicesWithApiKey(oInvoice);
        if (response?.status === 201) {
          message.success('Factura timbrada correctamente, enviando email...');

          let oSendInvoice = {
            email: values.email,
            cfdi: btoa(responseInvoice?.data?.stamp?.xml),
            serie: selected.serie,
            folio: selected.folio,
          };
          let rSendInvoice = await useSendInvoicesWithApiKey(oSendInvoice);
          if (rSendInvoice.status === 200) {
            if (rSendInvoice?.data?.status === 200) {
              message.success(rSendInvoice?.data?.message);
            } else {
              message.error(rSendInvoice?.data?.error);
            }
          } else {
            message.error('Error al enviar correo');
          }

          setModalLoading(false);
          formRef.resetFields();
          let newResponse = await usePatchOrdersWithApiKey(
            { status_invoice: 1 },
            selected.id
          );

          if (newResponse?.status === 200) {
            setSelected({});

            setModal(false);
            updater();
          } else {
            const { data } = response;
            if (data?.code === 400) {
              let sErrors = '';
              if (Array.isArray(data.errors)) {
                for (const oError of data.errors) {
                  if (oError.type === 'unique violation') {
                    sErrors += `El valor ${oError.path} ya existe en BD\n`;
                  }
                }
              } else {
                for (const sKey in data?.errors) {
                  sErrors += data.errors[sKey] + '\n';
                }
              }
              message.error(data?.message);
              if (sErrors !== '') {
                notification.error({
                  message: sErrors,
                  title: 'Errores',
                });
              }
            } else if (data?.code === 409) {
              message.error('Valor duplicado');
            } else {
              message.error('Error');
            }
          }
          setModalLoading(false);
        } else {
          const { data } = response;
          if (data?.code === 400) {
            let sErrors = '';
            if (Array.isArray(data.errors)) {
              for (const oError of data.errors) {
                if (oError.type === 'unique violation') {
                  sErrors += `El valor ${oError.path} ya existe en BD\n`;
                }
              }
            } else {
              for (const sKey in data?.errors) {
                sErrors += data.errors[sKey] + '\n';
              }
            }
            message.error(data?.message);
            if (sErrors !== '') {
              notification.error({
                message: sErrors,
                title: 'Errores',
              });
            }
            setModalLoading(false);
          } else if (data?.code === 409) {
            message.error('Valor duplicado');
            setModalLoading(false);
          } else {
            message.error('Error');
            setModalLoading(false);
          }
        }
        setModalLoading(false);
      }
    }
    setModalLoading(false);
  };

  const handleSearch = () => {
    setSearchFlag(true);
    search.branch_id = branch_id;
    aSearchElements.push({
      label: 'Sucursal',
      name: 'branch_id',
      type: 'branch',
    });
    change(generateQueries(search, aSearchElements));
    aSearchElements.pop();
  };

  const handleSend = async values => {
    setModalLoading2(true);

    let oOrder = await process(
      FIND_KEY,
      'invoices',
      {},
      { queries: `order_id=${selected.id}`, skip: 0 }
    );

    let oSendInvoice = {
      email: values.email,
      cfdi: btoa(oOrder?.data.data[0].cfdi),
      serie: oOrder?.data.data[0].serie,
      folio: oOrder?.data.data[0].folio,
    };
    let rSendInvoice = await useSendInvoicesWithApiKey(oSendInvoice);
    if (rSendInvoice.status === 200) {
      if (rSendInvoice?.data?.status === 200) {
        message.success(rSendInvoice?.data?.message);
      } else {
        message.error(rSendInvoice?.data?.error);
      }
      setModalLoading2(false);
      setModal2(false);
      formRef.resetFields();
    } else {
      message.error('Error al enviar correo');
      setModalLoading2(false);
      setModal2(false);
      formRef.resetFields();
    }
  };

  return (
    <Row
      justify="center"
      style={{ height: '100vh', background: '#f7f7f7' }}
      gutter={[16, 16]}
    >
      <Col span={8} xs={0} sm={0} md={0} lg={8} xl={8}>
        <Row gutter={[24, 24]} style={{ textAlign: 'center' }}>
          <Col span={24} className="hide-on-mobile">
            <img
              src={IMG1}
              alt="Logo"
              style={{ width: '100%', height: 'auto' }}
            />
          </Col>
          <Col className="hide-on-mobile">
            <img
              src={IMG2}
              alt="Logo"
              style={{ width: '100%', height: 'auto' }}
            />
          </Col>
        </Row>
      </Col>
      <Col
        span={8}
        style={{ textAlign: 'center' }}
        xs={24}
        sm={24}
        md={24}
        lg={8}
        xl={8}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <img
              src={logo}
              alt="Logo"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          </Col>
          <Col span={24}>
            <h2>Busca tu ticket.</h2>
          </Col>
          <Col span={24}>
            <Button type="dashed" block onClick={() => nav('/generateInvoice')}>
              <ArrowLeftOutlined /> Regresar
            </Button>
          </Col>

          <Col span={24}>
            <h1
              style={{
                color: '#001529',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              Tickets de la sucursal <br /> {branch ? 'B HOUSE' : ''}
            </h1>

            <SearchBar
              elements={aSearchElements}
              {...{
                handleReset,
                handleSearch,
                search,
                setSearch,
                requiredFields,
              }}
            />
            <TicketActions
              fn={{
                handler: setModalLoading,
                handlerSelected: setSelected,
                selected: selected,
                oClient: client,
              }}
              modal={{
                form: formRef,
                handler: setModal,
                loading: modalLoading,
                submit: handleSubmit,
                visible: modal,
              }}
              modal2={{
                form: formRef,
                handler: setModal2,
                loading: modalLoading2,
                submit: handleSend,
                visible: modal2,
              }}
            />
            {searchFlag === true ? (
              <Table
                columns={aColumns}
                dataSource={loading === false && orders?.data}
                loading={loading}
                pagination={{
                  current: orders?.skip ? orders.skip / 10 + 1 : 1,
                  onChange: e =>
                    change(
                      generateQueries(search, aSearchElements),
                      (e - 1) * orders.limit
                    ),
                  pageSizeOptions: [10],
                  total: orders.total,
                }}
                scroll={{ x: true }}
                rowKey={row => row.id}
              />
            ) : (
              <Table
                columns={aColumns}
                pagination={{
                  current: orders?.skip ? orders.skip / 10 + 1 : 1,
                  onChange: e =>
                    change(
                      generateQueries(search, aSearchElements),
                      (e - 1) * orders.limit
                    ),
                  pageSizeOptions: [10],
                  total: orders.total,
                }}
                rowKey={row => row.id}
                scroll={{ x: true }}
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col span={8} xs={0} sm={0} md={0} lg={8} xl={8}>
        <Row gutter={[24, 24]}>
          <Col>
            <img
              src={IMG3}
              alt="Logo"
              style={{ width: '100%', height: 'auto' }}
            />
          </Col>
          <Col>
            <img
              src={IMG4}
              alt="Logo"
              style={{ width: '100%', height: 'auto' }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
