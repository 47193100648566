import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { FIND, process } from '../Service/Api';
import { apiKey, getServer } from '../Utils/url';
import axios from 'axios';

export const useFetchInvoices = (queries = '', skip = 0, limit = 10) => {
  const [data, setData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ queries, limit, skip });

  const getInvoices = useCallback(async () => {
    setLoading(true);
    const response = await process(FIND, 'invoices', {}, params);
    if (response?.ok) {
      setData(response.data);
    } else {
      message.error('Error al consultar facturas');
    }
    setLoading(false);
  }, [process, params]);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  const onChangeParams = (q = '', s = 0, l = 10) =>
    setParams({ queries: q, limit: l, skip: s });

  const update = () => getInvoices();

  return [data, loading, onChangeParams, update];
};

export const useSaveInvoicesWithApiKey = async invoiceData => {
  const baseURL = getServer();
  const key = apiKey();

  let response;
  try {
    const config = {
      method: 'post',
      maxBodyLength: 'Infinity',
      url: `${baseURL}/invoices`,
      headers: {
        'x-api-key': key,
      },
      data: invoiceData,
    };
    response = await axios(config);
  } catch (error) {
    message.error('Error al intentar crear la factura');
  }
  if (response) {
    return response;
  } else {
    message.error('Error al crear factura');
  }
};

export const useStampInvoicesWithApiKey = async invoiceData => {
  const baseURL = getServer();
  const key = apiKey();

  let response;
  try {
    const config = {
      method: 'post',
      maxBodyLength: 'Infinity',
      url: `${baseURL}/invoices/stamp`,
      headers: {
        'x-api-key': key,
      },
      data: invoiceData,
    };
    response = await axios(config);
  } catch (error) {
    message.error('Error al intentar timbrar la factura');
  }
  if (response) {
    return response;
  } else {
    message.error('Error al timbrar factura');
  }
};

export const useSendInvoicesWithApiKey = async invoiceData => {
  const baseURL = getServer();
  const key = apiKey();

  let response;
  try {
    const config = {
      method: 'post',
      maxBodyLength: 'Infinity',
      url: `${baseURL}/invoices/send`,
      headers: {
        'x-api-key': key,
      },
      data: invoiceData,
    };
    response = await axios(config);
  } catch (error) {
    message.error('Error al intentar timbrar la factura');
  }
  if (response) {
    return response;
  } else {
    message.error('Error al timbrar factura');
  }
};
