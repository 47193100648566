import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  Card,
  Typography,
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
} from 'antd';
import logo from '../../Assets/img/logo.svg';
import { ArrowLeftOutlined, LockOutlined } from '@ant-design/icons';
import { getServer, apiKey } from '../../Utils/url';
import { create } from 'apisauce';
const API = create({
  baseURL: getServer(),
});

const { Title, Text } = Typography;

const ResetPasswordForm = () => {
  const key = apiKey();
  const [redirect, setRedirect] = useState(false);
  // eslint-disable-next-line no-undef
  const query = new URLSearchParams(window.location.search);

  const onSubmit = async data => {
    const myQueryValor = query.get('q');

    const response = await API.patch(`/forgot/${myQueryValor}`, data, {
      headers: {
        'x-api-key': key,
      },
    });
    if (response?.ok) {
      notification.success({
        description: 'Su contraseña fue restablecida corectamente',
      });
      setTimeout(() => {
        setRedirect(true);
      }, 3000);
    } else {
      notification.error({
        message: response.data.message,
        description: 'ocurrio un error',
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: '#001529',
        color: '#fff',
      }}
    >
      {redirect && <Redirect to="/" />}
      <div style={{ width: '400px' }}>
        <Row style={{ marginBottom: '20px' }}>
          <Col span={24}>
            <img src={logo} />
          </Col>
        </Row>
        <Card>
          <div style={{ textAlign: 'center' }}>
            <Title level={4} style={{ marginBottom: '1rem' }}>
              Restablecer la contraseña! <LockOutlined />
            </Title>
            <Text style={{ marginBottom: '3rem' }}>
              Su nueva contraseña debe ser diferente de las contraseñas
              utilizadas anteriormente
            </Text>
          </div>
          <Form
            onFinish={onSubmit}
            className="login-form-container"
            style={{ marginTop: '2rem' }}
          >
            <Form.Item
              label="Nueva contraseña"
              name="password"
              rules={[
                { required: true, message: 'Por favor ingrese una contraseña' },
              ]}
            >
              <Input.Password
                autoFocus
                autoComplete="off"
                name="password"
                placeholder="Ingrese una contraseña"
                allowClear
                prefix={<LockOutlined />}
              />
            </Form.Item>
            <Form.Item
              label="Confirmar contraseña"
              name="rpassword"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Por favor confirme su contraseña' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'Las dos contraseñas ingresadas no coinciden'
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                autoComplete="off"
                name="rpassword"
                placeholder="Confirme su contraseña"
                allowClear
                prefix={<LockOutlined />}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              style={{
                width: '100%',
                marginBottom: '1rem',
                backgroundColor: '#BAEE55',
                borderColor: '#BAEE55',
                color: '#fff',
              }}
            >
              Establecer nueva contraseña
            </Button>
          </Form>
          <div style={{ textAlign: 'center' }}>
            <Link
              to="/login"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#000',
              }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: '0.5rem', transform: 'rotate(180deg)' }}
              />
              <span>Volver a iniciar sesión</span>
            </Link>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
