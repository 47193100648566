export const aSearchElements = [
  {
    label: 'Cliente',
    name: 'client_id',
    placeholder: 'Todos',
    type: 'client',
  },
];

export const oInitState = {
  name: '',
};
