import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  message,
  notification,
  Popconfirm,
  Typography,
  Row,
  Table,
  Tag,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  FileSyncOutlined,
} from '@ant-design/icons';
import { SearchBar } from '../Atoms/SearchBar';
import { aSearchElements, oInitState } from './BranchesPaymentConstants';
import { generateQueries } from '../../Utils/query';
import { useFetchBranchesPay } from '../../Hooks/BranchesPayment.hook';
import { BranchesPaymentActions } from './BranchesPaymentActions';
import { process, SAVE, UPDATE } from '../../Service/Api';
const { Text } = Typography;

export const BranchesPaymentTable = () => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const [search, setSearch] = useState(oInitState);
  const [formsPay, loading, change, updater] = useFetchBranchesPay();
  const [formRef] = Form.useForm();

  const handleDeactivate = async sId => {
    const response = await process(
      UPDATE,
      'payment-forms-branch',
      { status: 0 },
      { id: sId }
    );
    if (response?.ok) {
      message.success('Desactivado correctamente');
      updater();
    } else {
      message.error('Error al desactivar');
    }
  };

  const aColumns = [
    {
      dataIndex: 'code',
      title: 'Codigo Interno',
    },
    {
      dataIndex: 'payment_form',
      title: 'Codigo SAT',
      render: ({ description, code }) => (
        <>
          <Text type="secondary">{`${code}`}</Text>--
          <Text>{`${description}`}</Text>
        </>
      ),
    },
    {
      dataIndex: 'description',
      title: 'Descripcion',
    },
    {
      dataIndex: 'branch',
      title: 'Sucursal',
      render: ({ trade_name, slug }) => (
        <>
          <Text>{`${trade_name}`}</Text>
          <Text type="secondary">{` (${slug})`}</Text>
        </>
      ),
    },
    {
      dataIndex: 'status',
      title: 'Estatus',
      render: status =>
        status ? (
          <Tag color="#87d068">Activo</Tag>
        ) : (
          <Tag color="#f50">Inactivo</Tag>
        ),
    },
    {
      dataIndex: 'id',
      title: 'Acciones',
      render: (_, row) => {
        return (
          <Row>
            <Button
              onClick={() => {
                setSelected(row);
                formRef.setFieldsValue(row);
                setModal(true);
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              onConfirm={() => handleDeactivate(row.id)}
              title="Está seguro de desactivar esta Forma de pago?"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Row>
        );
      },
    },
  ];

  const handleReset = () => {
    setSearch(oInitState);
    change();
  };

  const handleSearch = () => change(generateQueries(search, aSearchElements));

  const handleSubmit = async values => {
    setModalLoading(true);
    let response;
    let oSend = { ...values };
    if (selected?.id) {
      response = await process(UPDATE, 'payment-forms-branch', oSend, {
        id: selected.id,
      });
    } else {
      response = await process(SAVE, 'payment-forms-branch', oSend);
    }
    setModalLoading(false);
    if (response?.ok) {
      message.success('Exito');
      formRef.resetFields();
      setSelected({});
      setModal(false);
      updater();
    } else {
      const { data } = response;
      if (data?.code === 400) {
        let sErrors = '';
        if (Array.isArray(data.errors)) {
          for (const oError of data.errors) {
            if (oError.type === 'unique violation') {
              sErrors += `El valor ${oError.path} ya existe en BD\n`;
            }
          }
        } else {
          for (const sKey in data?.errors) {
            sErrors += data.errors[sKey] + '\n';
          }
        }
        message.error(data?.message);
        if (sErrors !== '') {
          notification.error({
            message: sErrors,
            title: 'Errores',
          });
        }
      } else if (data?.code === 409) {
        message.error('Valor duplicado');
      } else {
        message.error('Error en planes');
      }
    }
  };

  return (
    <div>
      <Row justify="space-between">
        <Col className="screen-title">
          <h3>Formas de Pago SUCURSAL</h3>
          <h3>
            <Tag>
              {formsPay?.total >= 0 ? formsPay.total : <LoadingOutlined />}
            </Tag>
          </h3>
        </Col>
        <Col>
          <Button onClick={() => setModal(true)} type="primary">
            Registrar <FileSyncOutlined />
          </Button>
        </Col>
      </Row>
      <BranchesPaymentActions
        fn={{
          handler: setModalLoading,
          handlerSelected: setSelected,
          selected,
        }}
        modal={{
          form: formRef,
          handler: setModal,
          loading: modalLoading,
          submit: handleSubmit,
          visible: modal,
        }}
      />
      <SearchBar
        elements={aSearchElements}
        {...{
          handleReset,
          handleSearch,
          search,
          setSearch,
        }}
      />
      <Table
        columns={aColumns}
        dataSource={formsPay?.data}
        loading={loading}
        pagination={{
          current: formsPay?.skip ? formsPay.skip / 10 + 1 : 1,
          onChange: e =>
            change(
              generateQueries(search, aSearchElements),
              (e - 1) * formsPay.limit
            ),
          pageSizeOptions: [10],
          total: formsPay.total,
        }}
        rowKey={row => row.id}
      />
    </div>
  );
};
