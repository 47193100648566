import React from 'react';
import ContractSigningForm from '../../Components/Forms/ContractSigning.form';

export const ContractSigning = () => {
  return (
    <div className="contractSigning-screen">
      <ContractSigningForm />
    </div>
  );
};
