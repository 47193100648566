import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Card, Form, Input, Button, message, Popconfirm } from 'antd';
import { process, UPDATE } from '../../Service/Api';
import { UserOutlined, MailOutlined } from '@ant-design/icons';
import { useAuth } from '../../Hooks';
import { useFetchUsers } from '../../Hooks/User.hook';
import logo from '../../Assets/img/avatar-294480.svg';
import { Auth } from '../../Redux/reducers/auth';

export const ProfileTable = () => {
  //Form Final
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [, , , updater] = useFetchUsers();
  const [{ user, role }] = useAuth();

  const _handleDeactivate = async sId => {
    const response = await process(UPDATE, 'users', formData ? formData : {}, {
      id: sId,
    });
    if (response?.ok) {
      message.success('Actualizado correctamente');
      updater();
      dispatch(Auth.authUpdateUser(response.data));
    } else {
      message.error('Error al Actualizar');
    }
  };
  const onFinish = () => {
    if (formData) {
      _handleDeactivate(user._id);
    }
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <h3>Perfil</h3>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={18} lg={8}>
          <Card title={` Tipo de usuario: ${role.rol_name}`} bordered={false}>
            Imagen:
            <img src={user.image != null ? user.image : logo} width="80px" />
          </Card>
        </Col>
        <Col span={2}></Col>
        <Col xs={24} md={18} lg={14}>
          <Card title="Información" bordered={false}>
            <Form
              onFinish={onFinish}
              initialValues={{
                username: `${user.username}`,
                email: `${user.email}`,
                full_name: user.full_name || '',
              }}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Usuario"
                    name="username"
                    rules={[{ required: true, message: 'Campo requerido' }]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          username: e.target.value,
                        })
                      }
                      type="text"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Campo requerido' }]}
                  >
                    <Input
                      prefix={<MailOutlined className="site-form-item-icon" />}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          email: e.target.value,
                        })
                      }
                      type="email"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <Form.Item label="Contraseña" name="password">
                    <Input.Password
                      placeholder="ingrese contraseña"
                      onChange={e =>
                        setFormData({
                          ...formData,
                          password: e.target.value,
                        })
                      }
                      type="password"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Repetir contraseña" name="rpassword">
                    <Input.Password
                      placeholder="Repetir contraseña"
                      onChange={e =>
                        setFormData({
                          ...formData,
                          rpassword: e.target.value,
                        })
                      }
                      type="password"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={24}>
                  <Form.Item
                    label="Nombre"
                    name="full_name"
                    rules={[{ required: true, message: 'Campo requerido' }]}
                  >
                    <Input
                      onChange={e =>
                        setFormData({
                          ...formData,
                          full_name: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <Popconfirm
                    onConfirm={() => onFinish()}
                    title="Está seguro de actualizar tus datos?"
                  >
                    <Button type="primary" htmlType="submit">
                      Guardar
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
