import React from 'react';
import { Select } from 'antd';

export const aSearchElements = [
  {
    label: 'Sucursal',
    name: 'branch_id',
    type: 'branch',
  },
  {
    label: 'Fecha',
    name: 'fecha',
    type: 'date',
  },
  {
    label: 'Serie',
    name: 'serie',
    type: 'input',
  },
  {
    label: 'Folio',
    name: 'folio',
    type: 'input-int',
  },
  {
    label: 'Estatus CFDI',
    name: 'status_invoice',
    placeholder: 'Todos',
    type: 'select',
    values: () => (
      <>
        <Select.Option value={0}>No Facturado</Select.Option>
        <Select.Option value={1}>Facturado</Select.Option>
        <Select.Option value={2}>Cancelado</Select.Option>
        <Select.Option value={3}>Pendiente</Select.Option>
      </>
    ),
  },
  {
    label: 'Estatus',
    name: 'status',
    placeholder: 'Todos',
    type: 'select',
    values: () => (
      <>
        <Select.Option value={1}>Activo</Select.Option>
        <Select.Option value={0}>Inactivo</Select.Option>
      </>
    ),
  },
];

export const oInitState = {
  serie: '',
  folio: '',
  status_invoice: undefined,
  status: undefined,
};
