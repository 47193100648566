import React from 'react';
import { InvoiceTable } from '../../Components/Invoices/InvoiceTable';

export const InvoicesScreen = () => {
  return (
    <div className="invoice-screen">
      <InvoiceTable />
    </div>
  );
};
