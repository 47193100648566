import {
  Col,
  Row,
  Form,
  Card,
  Input,
  Select,
  Button,
  message,
  Typography,
  notification,
} from 'antd';
import axios from 'axios';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';
import { useNavigation } from '../../Hooks';
import { apiKey, getServer } from '../../Utils/url';
import { generateQueries } from '../../Utils/query';
import { process, GET_KEY } from '../../Service/Api';
import { useSaveClientsWithApiKey } from '../../Hooks';
import { createContpaq } from '../../Service/ContpaqApi';
import { SearchSelector } from '../Atoms/SearchSelector';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useFetchRegimesWithApiKey } from '../../Hooks/Regime.hook';
import { useFetchZipcodesWithApiKey } from '../../Hooks/Zipcode.hook';
import { useFetchUsesCFDIWithApiKey } from '../../Hooks/UsesCFDI.hook';
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { validarRFC } from '../../Utils/checkRFC';
import logo from '../../Assets/img/logo.jpg';
import IMG1 from '../../Assets/img/IMG1.jpg';
import IMG2 from '../../Assets/img/IMG2.jpg';
import IMG3 from '../../Assets/img/IMG3.jpg';
import IMG4 from '../../Assets/img/IMG4.jpg';

const { Title, Text } = Typography;
const RegimeSearch = [
  {
    name: 'description',
    type: 'input',
    whitelist: true,
  },
  {
    name: 'status',
    type: 'select',
  },
];

const UsoSearch = [
  {
    name: 'description',
    type: 'input',
    whitelist: true,
  },
  {
    name: 'status',
    type: 'select',
  },
];

const ZipcodeSearch = [
  {
    name: 'codigo',
    type: 'input-int',
    whitelist: true,
  },
  {
    name: 'status',
    type: 'select',
  },
];

const Address = {
  street: '',
  neighborhood: '',
  city: '',
  state: '',
  country: 'Mexico',
  ext: '00',
  inte: '00',
};

const NewClientForm = () => {
  const key = apiKey();
  const baseURL = getServer();
  const [, nav] = useNavigation();
  const [zipCode, setZipCode] = useState('');
  const [oAddress, setAddress] = useState(Address);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [usos, uLoading, uQuery] = useFetchUsesCFDIWithApiKey();
  const [regimes, loading, rQuery] = useFetchRegimesWithApiKey();
  const [zipcodes, zLoading, zQuery] = useFetchZipcodesWithApiKey();

  const fetchClientWithApiKey = async rfc => {
    try {
      const config = {
        method: 'get',
        maxBodyLength: 'Infinity',
        url: `${baseURL}/clients?rfc=${rfc}`,
        headers: {
          'x-api-key': key,
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      message.error('Error al intentar crear cliente');
    }
  };

  const handleZipcode = s => {
    if (s.length > 4) {
      let sQueries = generateQueries({ codigo: s, status: 1 }, ZipcodeSearch);
      zQuery(sQueries, 0, 50);
    } else {
      zQuery();
    }
  };

  const handleSearchRegimes = s => {
    if (s.length > 2) {
      let sQueries = generateQueries(
        { description: s, status: 1 },
        RegimeSearch
      );
      rQuery(sQueries, 0, 50);
    } else {
      rQuery();
    }
  };

  const handleSearchUses = s => {
    if (s.length > 2) {
      let sQueries = generateQueries({ description: s, status: 1 }, UsoSearch);
      uQuery(sQueries, 0, 50);
    } else {
      uQuery();
    }
  };

  const getAddress = async values => {
    let oZipcode = await process(GET_KEY, 'zipcodes', {}, { id: values });

    if (oZipcode.data.codigo !== '') {
      setZipCode(oZipcode.data.codigo);
    }

    if (oZipcode.data.id) {
      setAddress(prevState => ({
        ...prevState,
        street: oZipcode.data?.asentamiento,
        neighborhood: oZipcode.data?.asentamiento,
        city: oZipcode.data?.municipio,
        state: oZipcode.data?.estado,
      }));
    }
  };

  const onSubmit = async values => {
    setIsSubmitting(true);
    let response;
    let responseContpaq;

    const validRFC = validarRFC(values.rfc);
    if (!validRFC) {
      message.error('RFC invalido');
      setIsSubmitting(false);
      return;
    }

    try {
      let oClient = await fetchClientWithApiKey(
        `rfc=${values.rfc}&branch_id=${values.branch_id}`
      );

      if (oClient.data.total === 0) {
        let oRegime = await process(
          GET_KEY,
          'regimes',
          {},
          { id: values.regime_id }
        );
        let oUses = await process(
          GET_KEY,
          'invoice-uses',
          {},
          { id: values.use_id }
        );
        let oCompany = await process(
          GET_KEY,
          'branches',
          {},
          { id: values.branch_id }
        );
        let oContpaq = {
          codigo: values.rfc.toUpperCase().substring(0, 9), //Temporalmente usamos una porcion del rfc
          codeCompany: oCompany.data.rfc,
          rfc: values.rfc.toUpperCase(),
          razonSocial: values.invoice_name.toUpperCase(),
          usoCFDI: oUses.data.code,
          regimen: `${oRegime.data.code}`,
          cNombreCalle: oAddress.street.toUpperCase(),
          exterior: oAddress.ext,
          interior: oAddress.inte ? oAddress.inte : 'SA',
          cColonia: oAddress.neighborhood.toUpperCase(),
          cCiudad: oAddress.city.toUpperCase(),
          cEstado: oAddress.state.toUpperCase(),
          cPais: oAddress.country.toUpperCase(),
          cCodigoPostal: zipCode,
        };

        responseContpaq = await createContpaq('restaurant/client', oContpaq);
        if (responseContpaq?.ok) {
          if (responseContpaq.status === 200) {
            if (oAddress.inte === null || oAddress.inte === '') {
              delete values.inte;
            }
            let oSend = {
              regime_id: values.regime_id,
              use_id: values.use_id,
              branch_id: values.branch_id,
              invoice_name: values.invoice_name,
              rfc: values.rfc,
              email: values.email,
              zipcode: zipCode,
              street: oAddress.street,
              neighborhood: oAddress.neighborhood,
              city: oAddress.city,
              state: oAddress.state,
              country: oAddress.country,
              contpaq: values.rfc.toUpperCase().substring(0, 9),
              ext: oAddress.ext,
              inte: oAddress.inte ? oAddress.inte : 'SA',
              status: 1,
            };
            response = await useSaveClientsWithApiKey(oSend);
            if (response?.status === 201) {
              message.info('Cliente creado con exito');
              nav(`/tickets/${values.rfc}&${values.branch_id}`);
            } else {
              const { data } = response;
              if (data?.code === 400) {
                let sErrors = '';
                if (Array.isArray(data.errors)) {
                  for (const oError of data.errors) {
                    if (oError.type === 'unique violation') {
                      sErrors += `El valor ${oError.path} ya existe en BD\n`;
                    }
                  }
                } else {
                  for (const sKey in data?.errors) {
                    sErrors += data.errors[sKey] + '\n';
                  }
                }
                message.error(data?.message);
                if (sErrors !== '') {
                  notification.error({
                    message: sErrors,
                    title: 'Errores',
                  });
                }
                setIsSubmitting(false);
              } else if (data?.code === 409) {
                message.error('Valor duplicado');
                setIsSubmitting(false);
              } else {
                message.error('Error en clientes');
                setIsSubmitting(false);
              }
            }
          } else {
            setIsSubmitting(false);
          }
        } else {
          message.error('Error de servidor del sistema Contpaq');
          setIsSubmitting(false);
        }
      } else {
        message.error('¡Ya te has registrado con este RFC anteriormente!');
        nav(`/tickets/${values.rfc}&${values.branch_id}`);
      }
    } catch (error) {
      message.error('Ha ocurrido un error al momento de crear el usuario');
      setIsSubmitting(false);
    }
  };

  return (
    <Row
      justify="center"
      style={{ height: '100vh', background: '#f7f7f7' }}
      gutter={[16, 16]}
    >
      <Col span={8} xs={0} sm={0} md={0} lg={8} xl={8}>
        <Row gutter={[24, 24]} style={{ textAlign: 'center' }}>
          <Col span={24}>
            <img
              src={IMG1}
              alt="Logo"
              style={{ width: '100%', height: 'auto' }}
            />
          </Col>
          <Col>
            <img
              src={IMG2}
              alt="Logo"
              style={{ width: '100%', height: 'auto' }}
            />
          </Col>
        </Row>
      </Col>
      <Col
        span={8}
        style={{ textAlign: 'center' }}
        xs={24}
        sm={24}
        md={24}
        lg={8}
        xl={8}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <img
              src={logo}
              alt="Logo"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          </Col>
          <Col span={24}>
            <Title level={4} style={{ marginBottom: '1rem' }}>
              Crear un nuevo cliente
            </Title>
            <Text style={{ marginBottom: '3rem' }}>
              Llena el siguiente formulario para crear un nuevo cliente.
            </Text>
          </Col>
          <Col span={24}>
            <Card>
              <Form
                onFinish={onSubmit}
                className="new-client-form-container"
                layout="vertical"
                style={{ marginTop: '2rem' }}
                initialValues={{
                  ['branch_id']: '64bffbe4-8694-4d53-a7dc-71fa73f7ecb4',
                }}
              >
                <Row gutter={[12, 12]}>
                  <Col span={12}>
                    <Form.Item
                      label="Razon Social"
                      name="invoice_name"
                      rules={[
                        {
                          required: true,
                          message: 'Campo requerido',
                        },
                        { max: 50, message: 'Maximo 50 Caracteres' },
                      ]}
                    >
                      <Input type="text" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="RFC"
                      name="rfc"
                      rules={[{ required: true, message: 'Campo requerido' }]}
                    >
                      <Input type="text" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Sucursal"
                      name="branch_id"
                      rules={[{ required: true, message: 'Campo requerido' }]}
                    >
                      <Select
                        style={{
                          width: '100%',
                          borderRadius: '5px',
                          backgroundColor: '#BAEE55',
                        }}
                      >
                        <Select.Option
                          key={'64bffbe4-8694-4d53-a7dc-71fa73f7ecb4'}
                          value={'64bffbe4-8694-4d53-a7dc-71fa73f7ecb4'}
                        >
                          {'B HOUSE'}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Correo Electronico"
                      name="email"
                      rules={[{ required: true, message: 'Campo requerido' }]}
                    >
                      <Input type="text" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Regimen Fiscal"
                      name="regime_id"
                      rules={[{ required: true, message: 'Campo requerido' }]}
                    >
                      <SearchSelector
                        allowClear
                        handleSearch={debounce(handleSearchRegimes, 200)}
                        loading={loading}
                        onChange={() => rQuery()}
                        placeholder="Seleccionar Regimen"
                      >
                        {regimes?.data.map(
                          oRegime =>
                            oRegime.status === 1 && (
                              <Select.Option
                                key={oRegime.id}
                                value={oRegime.id}
                              >
                                {oRegime.description} {oRegime.code}
                              </Select.Option>
                            )
                        )}
                      </SearchSelector>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Uso de CFDI"
                      name="use_id"
                      rules={[{ required: true, message: 'Campo requerido' }]}
                    >
                      <SearchSelector
                        allowClear
                        handleSearch={debounce(handleSearchUses, 200)}
                        loading={uLoading}
                        onChange={() => uQuery()}
                        placeholder="Seleccionar Uso de CFDI"
                      >
                        {usos?.data.map(
                          oUsos =>
                            oUsos.status === 1 && (
                              <Select.Option key={oUsos.id} value={oUsos.id}>
                                {oUsos.description} {oUsos.code}
                              </Select.Option>
                            )
                        )}
                      </SearchSelector>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Codigo Postal"
                      name="zipcode"
                      rules={[{ required: true, message: 'Campo requerido' }]}
                    >
                      <SearchSelector
                        allowClear
                        handleSearch={debounce(handleZipcode, 200)}
                        loading={zLoading}
                        onChange={e => {
                          getAddress(e);
                          zQuery();
                        }}
                        placeholder="Seleccionar Codigo Postal"
                      >
                        {zipcodes?.data.map(
                          oCode =>
                            oCode.status === 1 && (
                              <Select.Option key={oCode.id} value={oCode.id}>
                                {oCode.codigo} {oCode.asentamiento}
                              </Select.Option>
                            )
                        )}
                      </SearchSelector>
                    </Form.Item>
                  </Col>
                </Row>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <LoadingOutlined />{' '}
                      <span>Por favor aguarde unos instantes</span>
                    </>
                  ) : (
                    'Crear cliente'
                  )}
                </Button>
              </Form>
            </Card>
          </Col>

          <Col span={24}>
            <Link
              to="/generateInvoice"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#000',
              }}
            >
              <ArrowRightOutlined
                style={{ marginRight: '0.5rem', transform: 'rotate(180deg)' }}
              />
              Volver a generar factura
            </Link>
          </Col>
        </Row>
      </Col>
      <Col span={8} xs={0} sm={0} md={0} lg={8} xl={8}>
        <Row gutter={[24, 24]}>
          <Col>
            <img
              src={IMG3}
              alt="Logo"
              style={{ width: '100%', height: 'auto' }}
            />
          </Col>
          <Col>
            <img
              src={IMG4}
              alt="Logo"
              style={{ width: '100%', height: 'auto' }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default NewClientForm;
